.accordion-list {
	position: absolute;
	padding-top: 100px;
	right: 55px;
	z-index: 10;
	width: calc(100% - 32px);
	margin-top: 16px;
}

.list-item {
	background-color: ghostwhite !important;
	border-radius: 4px;
}

.row-printable {
	position: relative;
}

.panel {
	overflow: visible;
}

.header {
	margin-bottom: 20px;
}

.projectImage {
	width: 100%;
	background-color: ghostwhite;
}

.project-description {
	margin-bottom: 20px;
}

.material-table {
	margin-top: 20px;
}

.carousel .carousel-item {
	padding: 10px;
}

.totalPrice {
	position: relative;
	right: 0px;
	top: 20px;
	background-color: ghostwhite;
	padding: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-item-line {
  position: absolute;
  height: 100%;
  border-left: 1px solid #ddd;
}