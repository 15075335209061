@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
	height: 100%;
	width: 100%;
	font-family: "Source Sans Pro", sans-serif;
}

.app {
	display: flex;
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.sm {
	padding: 5px 8px !important;
	font-size: 12px !important;
}

/* PriceCard.css */
.price-card {
	position: relative;
	background-color: ghostwhite;
	margin-top: -240px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
	text-align: center;
	max-width: 200px;
	max-height: 125px;
	float: right;
}

.price-card-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.price-card-content h2 {
	margin: 0;
	font-size: 1.4em;
	font-weight: 800;
	font-family: 'Roboto', sans-serif;
	color: #2c3e50;
	font-style: italic;
}

.price-card .price {
	font-size: 35px;
	color: #007bff;
	margin-top: 20px;
	font-family: 'Roboto', sans-serif;
}

.addArticles {
	position: 'absolute' !important;
	top: -90px !important;
	right: 24px !important;
	float: right !important;
}

.list-unstyled {
	background-color: lavender;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.artCompTitle {
	position: relative;
	margin-left: 145px;
	text-align: center;
}

.input-number {
	width: 80px;
	padding: 5px;
	font-size: 14px;
	color: #555;
	border: 1px solid #ccc;
	border-radius: 4px;
	text-align: center;
}

.input-number:focus {
	border-color: #007bff;
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
	outline: none;
}

.datos-tabla {
	cursor: default;
}

.css-c44bvc-MuiButtonBase-root-MuiButton-root {
	float: right;
    top: -67px;
    right: -60px;
	line-height: 1.75 !important;
	text-transform: uppercase;
	min-width: 10px !important;
	min-height: 42px !important;
}

.css-17jjgke {
    margin: -5px auto -25px 5px !important;
}

.saveCompound {
    position: unset;
    margin-top: 15px;
}

.red-text {
	font-style: italic !important;
	font-weight: 600 !important;
	color: red !important;
}

.yellow-text {
	font-style: italic !important;
	font-weight: 600 !important;
	text-shadow: #000 !important;
	color: rgb(254, 182, 0) !important;
}

.green-text {
	font-style: italic !important;
	font-weight: 600 !important;
	text-shadow: #000 !important;
	color: rgb(0, 161, 48) !important;
}

.fila-par {
    background-color: #f1f2f3;
}

.fila-impar {
    background-color: #ffffff;
}